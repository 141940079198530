import React from "react"
import PropTypes from "prop-types"
import { graphql } from 'gatsby'

import LongformRow from "./LongformRow"

class Longform extends React.Component {
  

  list() {
    if (this.props.rows) {
      return this.props.rows.map((row, index) => (
        <LongformRow
          key={index}
          row={row}
          textColumnClassName={this.props.textColumnClassName}
          modalToggle={this.props.modalToggle}
          parent={this}
          narrowTextColumn={true}
          isCaseStudy={this.props.isCaseStudy}
        />
      ))
    } else {
      return []
    }
  }

  render() {
    return <div className="longform">
      {this.list()}
    </div>
  }
}

Longform.propTypes = {
  rows: PropTypes.array,
  modalToggle: PropTypes.func,
  textColumnClassName: PropTypes.string,
}

export default Longform

//
// Each fields
//
export const longformFragment = graphql`
  fragment longformFragment on ContentfulContent {
    id
    title
    layout
    mediasLayout
    imagesPerRow
    darkBackground
    narrowTextColumn
    narrowMediaColumn
    fullWidthImagesContainer
    content {
      childMarkdownRemark {
        html
      }
    }
    images {
      id
      title
      description
      gatsbyImageData(width: 1800, quality: 80)
    }
    instaStories
    caseStudyAssets
    inlineVideos {
      id
      file {
        url
        fileName
        contentType
      }
    }
    embeddedPlayers {
      id
      code {
        id
        code
      }
      emdeddedUrl
      previewImage {
        id
        title
        description
        gatsbyImageData(width: 1800, quality: 80)
      }
    }
  }
`