import React, { useRef } from "react"
import Fade from "react-reveal/Fade"

import Flickity from "react-flickity-component"
import { Player, ControlBar, VolumeMenuButton } from "video-react"

import EmbeddedPlayer from "../../components/EmbeddedPlayer"
import HtmlParser from "../../components/htmlParser"
import InlinePlayer from "../../components/InlinePlayer"
import FigureBox, { boxFormats } from "../../components/FigureBox"


const flickityOptions = {
  initialIndex: 1,
  wrapAround: false,
  pageDots: false,
  draggable: true,
  contain: true,
}

const DEFAULT_LAYOUT = `a`
const DEFAULT_MEDIAS_LAYOUT = `d`
const DEFAULT_IMAGES_PER_ROW = 2

const LongformRow = (props, isMuted) => {

  const playerRef= useRef([]);

  let {
    title,
    layout,
    mediasLayout,
    imagesPerRow,
    content,
    darkBackground,
    narrowTextColumn,
    narrowMediaColumn,
    fullWidthImagesContainer,
    embeddedPlayers,
    images,
    instaStories,
    inlineVideos,
    caseStudiesWorkDesign,
    caseStudyAssets,
  } = props.row

  // set a default layout
  layout = layout === undefined ? DEFAULT_LAYOUT : layout

  // set medias layout
  // if has only 1 images, default to DEFAULT_MEDIAS_LAYOUT constant
  mediasLayout =
    mediasLayout === undefined || (images && images.length === 1)
      ? DEFAULT_MEDIAS_LAYOUT
      : mediasLayout

  const videosPerRow = (imagesPerRow === null || imagesPerRow === undefined) ? 1 : imagesPerRow

  // images per row
  imagesPerRow =
    imagesPerRow === undefined ? DEFAULT_IMAGES_PER_ROW : imagesPerRow

  

  // main container className
  const containerClassName = () => {
    if(caseStudyAssets){
      return `container-fluid`
    }
    else if (layout === `c` || layout === `d`) {
      return `container`
    } 
    else{
      return `container-fluid`
    }
  }

  const isInverted = () => (darkBackground === true ? `is-inverted` : ``)

  const isNarrowTextColumn = () =>
    narrowTextColumn === true ? `is-narrow` : ``

  const isFullWidthImagesContainer = () =>
    fullWidthImagesContainer === true ? `is-full-width` : ``

  const isNarrowMediaContainer = () =>
    narrowMediaColumn === true ? `is-narrow` : ``

  const hasMedias = () =>
    images !== null || embeddedPlayers !== null ? `has-medias` : ``

  const layoutClassName = () =>
    `longform_row__${layout} ${isFullWidthImagesContainer()}`

  const mediasLayoutClassName = () =>
    `longform_row__medias longform_row__medias__${mediasLayout}`

  const inlinePlayersClassName = () => `longform_row__inline_players`

  const caseStudyClassName = () => (props.isCaseStudy === true ? `case__Study__media_asset my-0 py-0 images__per__row__${videosPerRow}`: ``)

  const textColumnClassName = () => {
    let classes = []
  

    // no column for some layouts
    if (layout === `c` || layout === `d` || layout === `g`) {
      classes.push(`entry`)
    } else {
      classes.push(`entry ${props.textColumnClassName}`)
    }

    // if narrow
    classes.push(isNarrowTextColumn())

    return classes.join(` `)
  }

  const totalMedias = () => {
    let total = 0
    if (images) total += images.length
    if (embeddedPlayers) total += embeddedPlayers.length
    return total
  }
  // setMuted = this.setMuted.bind(this)
  const setMuted = (index) => {
    // console.log(playerRef.current[index])
    if (playerRef.current[index].muted) {
      playerRef.current.forEach(player => {
        player.muted = true
      })
    }
    playerRef.current[index].muted
      ? (playerRef.current[index].muted = false)
      : (playerRef.current[index].muted = true)
  }

  const imagesList = () => {
    if (images) {
      return images.map((image, index) => (
        <Fade key={index} bottom delay={75 * (index / 0.95)} distance="6%">
            <picture onClick={e => handleModalToggle(e)} >
              <FigureBox
                tagName="div"
                source={image}
                alt={image.title}
                caption={image.description}
                type={`inline`}
                format={boxFormats.auto}
              />
            </picture>
          </Fade>
      ))
    } else {
      return []
    }
  }

  const inlineVideosList = () => {
    if (inlineVideos && !instaStories && !caseStudyAssets) {
      return inlineVideos.map((inlineVideo, index) => (
        <figure
          className={`${inlinePlayersClassName()} ${isNarrowMediaContainer()}`}
        >
          <div>
            {inlineVideo.file && (
              <InlinePlayer
                src={inlineVideo.file.url}
                resume={true}
                autoplay={false}
              />
            )}
          </div>
        </figure>
      ))
    } 
    else if (inlineVideos && !instaStories && caseStudyAssets) {
      return inlineVideos.map((inlineVideo, index) => (
          <div className={`box__count__${videosPerRow} `}>
            <figure
              className={`${inlinePlayersClassName()} ${isNarrowMediaContainer()}`}
            >
              <div>
                {inlineVideo.file && (
                  <Player
                    src={inlineVideo.file.url}
                    playsInline
                    muted={true}
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    fluid={true}
                  />
                )}
              </div>
            </figure>
          </div>
      ))
    }
    else if (inlineVideos && instaStories && !caseStudyAssets) {
      return (<aside className="pb-6">
                  <Flickity
                    className={`work__single__instagram`}
                    elementType={`div`}
                    reloadOnUpdate
                    static
                    options={flickityOptions}
                  >
                  {inlineVideos.map((inlineVideo, index) => (
                    <div
                        key={index}
                        className="work__single__instagram__slide"
                      >
                        {inlineVideo.file && (
                          <Player
                            // ref={player => { player[index] = player }}
                            ref={player => (playerRef.current[index] = player)}
                            playsInline
                            src={inlineVideo.file.url}
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            fluid={true}
                          >
                            <ControlBar
                              autoHide={false}
                              disableDefaultControls={true}
                            >
                              <VolumeMenuButton />
                            </ControlBar>
                            <div
                              className={`toggleButton`}
                              onClick={() => setMuted(index)}
                            />
                          </Player>
                        )}
                      </div>
      ))}
      </Flickity>
      </aside>)
    } 
    else {
      return []
    }
  }

  const playersList = () => {
    if (embeddedPlayers) {
      return embeddedPlayers.map((embed, index) => (
        <Fade key={index} bottom delay={75 * (index / 0.95)} distance="6%">
          <picture className="embed-responsive embed-responsive-16by9">
            {/* new method, with emdeddedUrl */}
            {!embed.code &&
              embed.emdeddedUrl && (
                <EmbeddedPlayer
                  url={embed.emdeddedUrl}
                  previewImage={embed.previewImage}
                />
              )}
            {/* old method */}
            {embed.code &&
              !embed.emdeddedUrl && (
                <div dangerouslySetInnerHTML={{ __html: embed.code.code }} />
              )}
          </picture>
        </Fade>
      ))
    } else {
      return []
    }
  }

  const medias = () => {
    if (images != null || embeddedPlayers != null) {
      return (
        <figure
          data-total-medias={totalMedias()}
          data-images-per-row={imagesPerRow === null ? 2: imagesPerRow}
          className={`${mediasLayoutClassName()} ${isNarrowMediaContainer()} ${isNarrowTextColumn()} ${isFullWidthImagesContainer()}`}
        >
          {imagesList()}
          {playersList()}
        </figure>
      )
    } else {
      return []
    }
  }

  const handleModalToggle = (e, index) => {
    if (props.modalToggle) {
      props.modalToggle(e, index)
    }
  }
  
  return (
    <div
      className={`${isInverted()} ${hasMedias()} ${isNarrowTextColumn()} ${containerClassName()} ${layoutClassName()} ${caseStudyClassName()}`}
    >
      {inlineVideosList()}
      {medias()}
      {/* show content or title */}
      {(content || title) && (
        <div className="row">
          <Fade bottom delay={75} distance="1%">
            <div
              className={`${textColumnClassName()} container longform_row__content`}
            >
              {title && <h3 className="longform_row__title">{title}</h3>}
              {content && (
                <div className="longform_row__content__text">
                  <HtmlParser
                    html={content.childMarkdownRemark.html}
                    entryClass={true}
                  />
                </div>
              )}
            </div>
          </Fade>
        </div>
      )}
    </div>
  )
}

export default LongformRow